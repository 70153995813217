import React from "react";

function Book(props) {
  return (
    <div>
      <div className="book_card mb-4">
        <div className="book_cover">
          <img
            src={require(`..//assets/images/${props.img}`)}
            alt="book cover"
            className="cover"
          />
        </div>
        <div className="book_info">
          <h4 className="book_title">{props.title}</h4>
          <p className="book_author">Author: {props.author}</p>
          <p className="book_category">{props.category}</p>         
        </div>
      </div>
    </div>
  );
}

export default Book;
