import React from "react";

function Package(props) {
  const { title, price, points } = props;

  const cardClassName = `package_card_wrapper ${
    title === "Reader" ? "reader" : "bookworm"
  }`;
  const tickImg = title === "Reader" ? "greenTick.png" : "whiteTick.png";

  return (
    <div className={cardClassName}>
      <div className="popular">
        <p>Best Value</p>
      </div>

      <div className="package_card">
        <div className="package_card_header">
          <h3 className="package_title">{title}</h3>
          <p className="package_price">
            Only For <span className="price">{price}</span>
          </p>
        </div>
        <div className="packags_card_body">
          <ul className="package_list">
            {points.map((point, index) => (
              <>
                <li key={index}>
                  <img
                    src={require(`../assets/images/${tickImg}`)}
                    alt="tick icon"
                    className="tick_icon"
                  />
                  {point}
                </li>
                <div className="package_line"></div>
              </>
            ))}
          </ul>
        </div>
        <div className="packages_card_footer">
          <a
            href="https://api.whatsapp.com/send?phone=96176025170&text=Hello,I am interested in subscribing to your membership."
            target="_blank"
            rel="noreferrer"
          >
            <button className="ctaBtn package_btn">Subscribe</button>
          </a>
        </div>
      </div>
    </div>
  );
}

export default Package;
