import React, { useState } from "react";
import Header from "../components/Header";
import { useNavigate } from "react-router-dom";

function ContactUs() {
  const [fullname, setfullname] = useState("");
  const [email, setEmail] = useState("");
  const [phonenum, setphonenum] = useState("");
  const [option, setoption] = useState("");
  const [message, setmessage] = useState("");
  const [inputErrors, setInputErrors] = useState({
    fullname: "",
    email: "",
    phonenum: "",
    option: "",
    message: "",
  });

  const [showPopup, setShowPopup] = useState(false);
  const [emailSent, setEmailSent] = useState(false);

  const navigate = useNavigate();

  const validateEmail = (input) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(input);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const newInputErrors = {};

    // Validate fullname
    if (!fullname) {
      newInputErrors.fullname = "Full Name is required.";
    }

    // Validate email
    if (!email) {
      newInputErrors.email = "Email is required.";
    } else if (!validateEmail(email)) {
      newInputErrors.email = "Invalid email format.";
    }

    // Validate phonenum
    if (!phonenum) {
      newInputErrors.phonenum = "Phone Number is required.";
    }

    // Validate option
    if (!option) {
      newInputErrors.option = "Option is required.";
    }

    // Validate message
    if (!message) {
      newInputErrors.message = "Message is required.";
    }

    setInputErrors(newInputErrors);

    // If any field has an error, stop the submission
    if (Object.keys(newInputErrors).length > 0) {
      return;
    }

    // Show processing popup
    setShowPopup(true);
    document.body.style.overflow = "hidden";
    // Proceed with form submission
    try {
      const response = await fetch("/contactus", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          fullname: fullname,
          email: email,
          phonenumber: phonenum,
          option: option,
          message: message,
        }),
      });

      const data = await response.json();

      if (data.sent) {
        setEmailSent(true);
        setTimeout(() => {
          setShowPopup(false);
          setEmailSent(false);
          setfullname("");
          setEmail("");
          setphonenum("");
          setoption("");
          setmessage("");
          navigate("/");
          document.body.style.overflow = "";
        }, 3000);
      } else {
        console.log("Error");
      }
    } catch (error) {
      console.log("Error:", error);
    }
  };

  return (
    <div id="home_page" className="contactUs_page">
      <div className="contactUs">
        <div className="hero_section">
          <Header />
          <div className="hero_section_wrapper">
            <div className="container">
              <div className="row">
                <div className="col-lg-12">
                  <div className="hero_content">
                    <h3 className="hero-title">
                      Get In <span className="bookIcon">&#x260E;</span> Touch
                      With <span className="colored_word">Our Team!</span>
                    </h3>
                    <p className="hero_description">
                      Feel free to reach out to us with any inquiries, feedback,
                      or questions - we're here to assist you!
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="contactUs_form">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="row">
                  <div className="col-lg-6">
                    <div className="form_wrapper">
                      <h3 className="contactus_form_title">
                        How Can We Help You?
                      </h3>
                      <form
                        action="#"
                        method="post"
                        className="form"
                        onSubmit={handleSubmit}
                        noValidate
                      
                      >
                        <div
                          className="form-group mb-4"
                       
                        >
                          <input
                            type="text"
                            id="fullname"
                            name="fullname"
                            className={
                              "form-control" +
                              (inputErrors.fullname ? " is-invalid" : "")
                            }
                            value={fullname}
                            onChange={(e) => setfullname(e.target.value)}
                            placeholder="Full Name"
                            required
                          />
                          {inputErrors.fullname && (
                            <div className="invalid-feedback">
                              {inputErrors.fullname}
                            </div>
                          )}
                        </div>

                        <div
                          className="form-group mb-4"
                        
                        >
                          <input
                            type="email"
                            id="email"
                            name="email"
                            placeholder="Email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            className={
                              "form-control" +
                              (inputErrors.email ? " is-invalid" : "")
                            }
                            required
                          />
                          {inputErrors.email && (
                            <div className="invalid-feedback">
                              {inputErrors.email}
                            </div>
                          )}
                        </div>

                        <div
                          className="form-group mb-4"
                         
                        >
                          <input
                            type="tel"
                            id="phonenum"
                            name="phonenum"
                            placeholder="Phone Number"
                            className={
                              "form-control" +
                              (inputErrors.phonenum ? " is-invalid" : "")
                            }
                            value={phonenum}
                            onChange={(e) => setphonenum(e.target.value)}
                            required
                          />
                          {inputErrors.phonenum && (
                            <div className="invalid-feedback">
                              {inputErrors.phonenum}
                            </div>
                          )}
                        </div>

                        <div
                          className="form-group mb-4"
                        
                        >
                          <select
                            id="option"
                            name="option"
                            value={option}
                            onChange={(e) => setoption(e.target.value)}
                            className={
                              "form-control" +
                              (inputErrors.option ? " is-invalid" : "")
                            }
                            required
                          >
                            <option value="">
                              --Please choose an option--
                            </option>
                            <option value="General Inquiry">
                              General Inquiry
                            </option>
                            <option value="Book Donations">
                              Book Donations
                            </option>
                            <option value="Membership Subscription">
                              Membership Subscription
                            </option>
                            <option value="Study Area Reservation">
                              Study Area Reservation
                            </option>
                            <option value="Meeting Area Reservation">
                              Meeting Area Reservation
                            </option>
                            <option value="Feedback">Feedback</option>
                            <option value="Other">Other</option>
                          </select>
                          {inputErrors.option && (
                            <div className="invalid-feedback">
                              {inputErrors.option}
                            </div>
                          )}
                        </div>

                        <div
                          className="form-group mb-4"
                        
                        >
                          <textarea
                            id="message"
                            name="message"
                            className={
                              "form-control" +
                              (inputErrors.message ? " is-invalid" : "")
                            }
                            rows="4"
                            cols="50"
                            value={message}
                            onChange={(e) => setmessage(e.target.value)}
                            placeholder="Your Message Here!"
                            required
                          ></textarea>
                          {inputErrors.message && (
                            <div className="invalid-feedback">
                              {inputErrors.message}
                            </div>
                          )}
                        </div>

                        <div
                          className="form-group"
                         
                        >
                          <button type="submit" className="ctaBtn submitBtn">
                            Submit
                          </button>
                        </div>
                      </form>
                    </div>
                  </div>

                  <div className="col-lg-6">
                    <div className="contactUs_info">
                      <h3 className="contactusInfo_title">Prefer To Chat?</h3>

                      <div
                        className="chating_option"
                     
                      >
                        <img
                          src={require("..//assets/images/email.png")}
                          alt="email icon"
                          className="social_icon"
                        />
                        <a
                          href="mailto:contact@hadisbookstore.com"
                          className="social_info"
                        >
                          contact@hadisbookstore.com
                        </a>
                      </div>

                      <div
                        className="chating_option"
                      
                      >
                        <img
                          src={require("..//assets/images/phone.png")}
                          alt="phone icon"
                          className="social_icon"
                        />
                        <a
                          href="https://api.whatsapp.com/send?phone=96176025170"
                          className="social_info"
                        >
                          +961-76025170
                        </a>
                      </div>

                      <div
                        className="chating_option"
                       
                      >
                        <img
                          src={require("..//assets/images/green_nstagram.png")}
                          alt="insta icon"
                          className="social_icon"
                        />
                        <a
                          href="https://www.instagram.com/hadiyehyabookstore/"
                          className="social_info"
                        >
                          @hadiyehyabookstore
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {showPopup && (
        <div className="popup">
          <div className="popup-content">
            {emailSent ? (
              <p>Email sent successfully!✅</p>
            ) : (
              <p>Your email is being sent 📤...</p>
            )}
          </div>
        </div>
      )}
    </div>
  );
}

export default ContactUs;
