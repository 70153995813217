import React from "react";
import Package from "../components/Package";
import Features from "../components/Features";
import { Link } from "react-router-dom";
import FAQCard from "../components/FAQ";
import Header from "../components/Header";
import Footer from "../components/Footer";

function Home() {
  const style1 = {
    position: "relative",
    padding: "56.25% 0 0 0",
  };
  const style2 = {
    position: "absolute",
    top: 0,
    borderRadius: "12px",
    left: 0,
    width: "100%",
    height: "100%",
  };
  const reader_points = [
    `Enjoy Free books as much as you can spare time in our library`,
    `Stay connected to free internet`,
    `Enjoy A Disciplined area to study`,
    `Borrow a book of your choice/switch it through the month!`,
  ];

  const bookworm_points = [
    `Enjoy Free books as much as you can spare time in our library`,
    `Stay connected to free internet`,
    `Enjoy A Disciplined area to study`,
    `Borrow up to 3 books of your choice/ switch it through the month!`,
    `Enjoy Your Free Cup of Coffee`,
  ];
  return (
    <div id="home_page">
      <div className="hero_section">
        <Header />
        <div className="hero_section_wrapper">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="hero_content">
                  <h1 className="hero-title">
                    Get Lost in the Magic of{" "}
                    <span className="bookIcon">&#128218;</span>Reading at Our{" "}
                    <span className="colored_word">
                      Cozy Library
                      <div className="underlined_word">
                        <img
                          src={require("..//assets/images/line.png")}
                          alt="line"
                        />
                      </div>
                    </span>
                  </h1>
                  <p className="hero_description">
                    Dive into the ultimate reading experience at our library!
                    From timeless classics to trending page-turners, our cozy
                    sanctuary is your go-to destination for literary adventures.
                    Say hello to endless reading possibilities and goodbye to
                    cluttered bookshelves !
                  </p>
                  <Link to="contactus">
                    <button className="ctaBtn heroBtn">
                      Join Our Membership
                    </button>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="introductory_vid">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="video_thumbnail">
                <div className="thumbnail">
                  <div style={style1}>
                    <iframe
                      src="https://player.vimeo.com/video/924087371?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
                      frameborder="0"
                      allow="autoplay; fullscreen; picture-in-picture; clipboard-write"
                      style={style2}
                      title="Hadi yeyha Bookstore"
                    ></iframe>
                  </div>
                  <script src="https://player.vimeo.com/api/player.js"></script>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="ourstory_section">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="ourstory_header">
                <p className="subtitle">Our Story</p>
                <h2 className="section_title">
                  About Hadi's <span className="colored_word">Library</span>
                </h2>
                <img
                  src={require("..//assets/images/zigzagLine.png")}
                  alt="Line"
                />
              </div>
              <div className="row">
                <div className="col-lg-6">
                  <div className="ourstory_content">
                    <h3 className="content_title">
                      The Story Behind Our Borrowing-First Bookstore!
                    </h3>
                    <p className="content_description">
                      Hadi Yehia the person behind this library is a Lebanese
                      individual with special needs. His passion for books has
                      led him to embark on a mission to transform it into a
                      community treasure.
                      <br /> <br />
                      Not like any other library or bookstore, we introduced a
                      new business model to the market that is borrowing books
                      instead of buying them. Bid farewell to traditional
                      ownership constraints and dive into a world where books
                      are meant to be borrowed, experienced, and shared.
                    </p>
                    <ul className="ourstory_list">
                      <li>
                        <img
                          src={require("..//assets/images/greenTick.png")}
                          alt="Tick"
                          className="tick_img"
                        ></img>
                        <p className="list_paragraph">
                          We exclusively offer second-hand books
                        </p>
                      </li>
                      <li>
                        <img
                          src={require("..//assets/images/greenTick.png")}
                          alt="Tick"
                          className="tick_img"
                        ></img>
                        <p className="list_paragraph">
                          We prioritize an eco-friendly approach towads reading
                        </p>
                      </li>
                      <li>
                        <img
                          src={require("..//assets/images/greenTick.png")}
                          alt="Tick"
                          className="tick_img"
                        ></img>
                        <p className="list_paragraph">
                          We provide working space and a descent place for
                          students{" "}
                        </p>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="ourstory_img">
                    <img
                      src={require("..//assets/images/library5.jpeg")}
                      alt="bookshelve"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="aboutUs_section">
        <div className="container">
          <div className="aboutUs_header">
            <h2 className="content_title">What Makes Us Different</h2>
            <img src={require("..//assets/images/zigzagLine.png")} alt="Line" />
          </div>
          <div className="row">
            <div className="col-lg-12">
              <div className="row">
                <div className="col-lg-6 col-md-6">
                  <div className="aboutImg aboutimg1">
                    {/* <img
                      src={require("..//assets/images/IMG_9653.jpg")}
                      alt="About"
                    /> */}
                  </div>
                </div>
                <div className="col-lg-6 col-md-6">
                  <div className="about_description">
                    <p className="about_subtitle">Borrowing Service</p>
                    <h4 className="about_description_title">
                      Introducing Our New Borrowing Service Model
                    </h4>
                    <p className="about_description_paragraph">
                      Not like any other libraries, we operate on a borrowing
                      service model.Our innovative borrowing service model
                      eliminates the need for purchasing books,promoting
                      sustainable reading practices for a greener future. You
                      don’t have to buy any book from our library, simply borrow
                      a book of your choice, read it and return it.
                    </p>
                  </div>
                  {/* </div> */}
                </div>
              </div>
              <div className="line">
                <img src={require("..//assets/images/line.png")} alt="line" />
              </div>
              <div className="row second_fam">
                <div className="col-lg-6 col-md-6">
                  <div className="aboutImg aboutimg2"></div>
                </div>
                <div className="col-lg-6 col-md-6">
                  <div className="about_description">
                    <p className="about_subtitle">Workspace & Study Area</p>
                    <h4 className="about_description_title">
                      Enjoy a Disciplined & Quite Area
                    </h4>
                    <p className="about_description_paragraph">
                      We provide a disciplined and quite workspace & study area
                      for both employees and students.Our dedicated workspace
                      ensures a tranquil atmosphere, free from distractions,
                      where individuals can concentrate fully on their tasks,
                      fostering a culture of academic and professional
                      excellence.
                    </p>
                  </div>
                </div>
              </div>

              <div className="line">
                <img src={require("..//assets/images/line.png")} alt="line" />
              </div>
              <div className="row">
                <div className="col-lg-6 col-md-6">
                  <div className="aboutImg aboutimg3">
                    {/* <img
                      src={require("..//assets/images/library1.jpeg")}
                      alt="About"
                    /> */}
                  </div>
                </div>
                <div className="col-lg-6 col-md-6">
                  <div className="about_description">
                    <p className="about_subtitle">Wide Collection</p>
                    <h4 className="about_description_title">
                      Explore A Wide Range of books in multiple languages
                    </h4>
                    <p className="about_description_paragraph">
                      Access a wide range of old and new books; our collection
                      consists of timeless classics and recent releases,
                      ensuring there's something for every reader, whether they
                      seek nostalgia or the latest literary trends.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="question_section">
        <div className="container">
          <div className="question_div">
            <h4 className="question">
              But how can you benefit from all our services at the lowest
              <span>&#128176;</span>price?
            </h4>
          </div>
        </div>

        <div className="books3d_img">
          <img src={require("..//assets/images/books3d.png")} alt="3d book" />
        </div>
      </div>

      <div className="packages_section">
        <div className="container">
          <div className="packages_header">
            <p className="subtitle">Be Part Of Our Community</p>
            <h2 className="content_title">
              Hadi’s Membership <span className="colored_word">Bundles</span>
            </h2>
            <img src={require("..//assets/images/zigzagLine.png")} alt="Line" />
          </div>
          <div className="row">
            <div className="col-lg-6 col-md-6">
              <Package title="Reader" price="$3" points={reader_points} />
            </div>
            <div className="col-lg-6 col-md-6">
              <Package title="Bookworm" price="$5" points={bookworm_points} />
            </div>
          </div>
        </div>
      </div>

      <div className="features_section">
        <div className="container">
          <div className="features_header">
            <h3 className="content_title">
              Here’s what you can expect by{" "}
              <span className="colored_word">
                Joining Us
                <div className="underlined_word">
                  <img src={require("..//assets/images/line.png")} alt="line" />
                </div>
              </span>
            </h3>
          </div>

          <div className="row">
            <div className="col-lg-4 col-md-6">
              <Features
                img={require("..//assets/images/studyzoneicon.png")}
                title="Quiet study zone"
                description="Enjoy a  focused atmosphere in our quiet study zone, where distractions are minimized for optimal concentration"
              />
            </div>
            <div className="col-lg-4 col-md-6">
              <Features
                img={require("..//assets/images/booksicon.png")}
                title="Cozy Space"
                description="Step into our library and discover a cozy haven, where comfortable seating and inviting ambiance await you."
              />
            </div>
            <div className="col-lg-4 col-md-6">
              <Features
                img={require("..//assets/images/wifiicon.png")}
                title="Fast Wifi"
                description="Stay productive and connected with our reliable and high-speed WiFi.No need to miss any session or meeting because of bad internet after today"
              />
            </div>
          </div>
        </div>
      </div>

      <div className="howtohelp_section">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="row">
                <div className="col-lg-6">
                  <div className="left_section">
                    <h4 className="left_title">
                      How Can You Help Us{" "}
                      <span className="colored_word">
                        Grow & Expand?
                        <div className="underlined_word">
                          <img
                            src={require("..//assets/images/line.png")}
                            alt="line"
                          />
                        </div>
                      </span>
                    </h4>

                    <p className="left_description">
                      You can be anywhere in Lebanon, and we will find a{" "}
                      <strong>convenient location</strong> for both of us to
                      pick up the books.
                    </p>
                    <Link to="/contactus">
                      <button className="ctaBtn donateBtn">
                        Donate Your Books
                      </button>
                    </Link>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="right_section">
                    <h5 className="right_title">Here's How You Can Help!</h5>
                    <ul className="right_list">
                      <li>
                        <img
                          src={require("..//assets/images/greenTick.png")}
                          alt="Tick Icon"
                        />{" "}
                        <p>
                          You love reading and have a Good collection of New &
                          Old books.
                        </p>
                      </li>
                      <li>
                        <img
                          src={require("..//assets/images/greenTick.png")}
                          alt="Tick Icon"
                        />{" "}
                        <p>You want to join our cause & support education</p>
                      </li>
                      <li>
                        <img
                          src={require("..//assets/images/greenTick.png")}
                          alt="Tick Icon"
                        />{" "}
                        <p>
                          You Want To Share The Books You Love With The World
                        </p>
                      </li>
                      <li>
                        <img
                          src={require("..//assets/images/greenTick.png")}
                          alt="Tick Icon"
                        />{" "}
                        <p>
                          You Are Ready To Donate These Books To Our Library{" "}
                        </p>
                      </li>
                    </ul>

                    <p className="right_note">
                      If You Tick all the above,contact us on whatsapp to
                      discuss all details.{" "}
                      <span className="red">
                        Note That Your Books WILL NEVER BE SOLD.
                      </span>{" "}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="two_lines">
        <img
          src={require("..//assets/images/doubleLine.png")}
          alt="double line"
        />
      </div>

      <div className="faq_section">
        <div className="container">
          <div className="row">
            <div className="faq_content">
              <p className="faq_subtitle">FAQ</p>
              <h5 className="faq_title">
                {" "}
                <span className="colored_word underlined_word">
                  Find Answers{" "}
                </span>
                To Your Questions Below
              </h5>
            </div>
            <div className="col-lg-8 m-auto">
              <div className="faq_questions">
                <div>
                  <FAQCard
                    title="Where is the library located?"
                    content="The library is centrally located in Brih-Al Chouf, ensuring convenient access for local residents and visitors alike."
                  />
                </div>
                <div>
                  <FAQCard
                    title="What types of books are available in the library's collection?"
                    content="Our library boasts a diverse collection covering a wide range of genres including novels, history, fiction, non-fiction, encyclopedias, children's literature, and poetry, available in English, Arabic, and French languages."
                  />
                </div>
                <div>
                  <FAQCard
                    title="What are the opening hours of the library?"
                    content="The library is open every day from 10:00 AM to 6:00 PM, offering visitors all time needed to explore our collection. Additionally, if you wish to use our workspace outside of these hours, you can call ahead and reserve your spot."
                  />
                </div>
                <div>
                  <FAQCard
                    title="Can I borrow books if I'm not a member?"
                    content="No, only members have the privilege of borrowing books and taking them home. However, you are welcome to read any book of your choice during your visit to our library."
                  />
                </div>
                <div>
                  <FAQCard
                    title="Does the library offer any services , such as study or meeting rooms?"
                    content="Yes, we provide dedicated study and workspace areas for students, freelancers, and remote workers. Our workspace is equipped with 24/7 internet and electricity access for uninterrupted productivity. Additionally, you have the option to reserve the library for full-day or partial-day meetings if you prefer a private setting,you only have to call ahead and reserve your spot."
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default Home;
