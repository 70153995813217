import React from "react";

function Features(props) {
  const imgPath = props.img;
  const expectedPath = require("../assets/images/wifiicon.png");
  const isEqual = imgPath === expectedPath;
const imgClass = isEqual ? "wifi" : "";
 
  return (
    <div className="features">
      <div className="features_card">
        <div className={`features_img ${imgClass}`}>
          <img src={props.img} alt="Icon" />
        </div>
        <div className="feature_card_content">
          <h5 className="card_title">{props.title}</h5>
          <p className="card_description">{props.description}</p>
        </div>
      </div>
    </div>
  );
}

export default Features;
