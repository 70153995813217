import React from "react";
import { Link } from "react-router-dom";

function Footer() {
  const handleLogoClick = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  return (
    <div className="footer">
      <div className="top_footer">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <Link to="/" onClick={handleLogoClick}>
                <div className="footer_logo">
                  <img src={require("..//assets/images/logo.png")} alt="logo" />
                </div>
              </Link>
              <h6 className="footer_title">
                Join our expanding community today!
              </h6>
              <p className="footer_description">
                Join Us At Hadi's Library, where you can find all kinds of books
                mixed with a cozy & disciplined space
              </p>
              <Link to="/contactus" onClick={handleLogoClick}>
                <button className="ctaBtn footer_btn">
                  Join Our Membership
                </button>
              </Link>

              <div className="instaIcon">
                <a href="https://www.instagram.com/hadiyehyabookstore/">
                  <img
                    src={require("..//assets/images/instagram.png")}
                    alt="istagramlogo"
                  />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="bottom_footer">
        <div className="container">
          <div className="copyrights_section">
            <p>Hadi's Bookstore-© 2024 All Rights Reserved</p>

            <p className="developer">
              Developed By{" "}
              <a href="https://growwithalaa.com/" target="_blank" rel="noreferrer">
                Alaa Abi Ezzddine
              </a>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Footer;
