import React, { useState, useEffect } from "react";
import Header from "../components/Header";
import Book from "../components/Book";

function Shop() {
  const [books, setBooks] = useState([]);
  const [key, setKey] = useState("");
  const [category, setCategory] = useState("");
  const [notAvailable, setNotAvailable] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const booksPerPage = 16;

  const bookData = [
    {
      _id: "6638e4353d169d8ba8b806f8",
      title: "The Wild Sheep Chase",
      author: "Murakami",
      category: "Novel",
      cover: "thewildsheepchase.JPG",
      __v: 0,
    },
    {
      _id: "6638e45e1732b941fe8c74f3",
      title: "Kafka On The Shore",
      author: "Murakami",
      category: "Novel",
      cover: "kafkaontheshore.JPG",
      __v: 0,
    },
    {
      _id: "6639e9ce8d422f4b4a370077",
      title: "Before The Coffee Gets Cold",
      author: "Toshikazu Kawaguchi",
      category: "Novel",
      cover: "beforethecoffeegetscold.JPG",
      __v: 0,
    },
    {
      _id: "663b495aae3d83941d7e6099",
      title: "Noise",
      author: "Daneil Kahneman",
      category: "Self Help",
      cover: "Noise.jpeg",
      __v: 0,
    },
    {
      _id: "663b4987ae3d83941d7e609c",
      title: "The Holocaust Industry",
      author: "Normen Finkelstein",
      category: "Politic",
      cover: "The Holocaust Industry.jpg",
      __v: 0,
    },
    {
      _id: "663b49ffae3d83941d7e609f",
      title: "7 Habits Of Highly Effective People",
      author: "Stephen Covey",
      category: "Self Help",
      cover: "7 habits of highly effective people.jpg",
      __v: 0,
    },
    {
      _id: "663b4a5fae3d83941d7e60a2",
      title: "A Line In The Sand",
      author: "James Barr",
      category: "Novel",
      cover: "A Line In The Sand.jpeg",
      __v: 0,
    },
    {
      _id: "663b4a7bae3d83941d7e60a5",
      title: "A Tale Of 2 Cities",
      author: "Charles Dickens",
      category: "Novel",
      cover: "A Tale of  2 cities.jpg",
      __v: 0,
    },
    {
      _id: "663b4b11ae3d83941d7e60a8",
      title: "Safe Haven",
      author: "Nicholas Sparks",
      category: "Novel",
      cover: "Safe Haven.jpg",
      __v: 0,
    },
    {
      _id: "663b4b28ae3d83941d7e60ab",
      title: "Gilgamesh",
      author: "Herbert Mason",
      category: "Novel",
      cover: "Gilgamesh.jpg",
      __v: 0,
    },
    {
      _id: "663b4b42ae3d83941d7e60ae",
      title: "Leaks,Hacks,& Scandals",
      author: "Tarek El Ariss",
      category: "Non-Fiction",
      cover: "Leaks,Hacks,& Scandals.jpg",
      __v: 0,
    },

    {
      _id: "663b4b68ae3d83941d7e60b1",
      title: "The Art Of War",
      author: "Sun Tzu's",
      category: "Non-Fiction",
      cover: "The Art Of War Plus The Art of Marketing.jpg",
      __v: 0,
    },
    {
      _id: "663b4b86ae3d83941d7e60b4",
      title: "Money Master The Game",
      author: "Tony Robbins",
      category: "Self Help",
      cover: "Money Master the game.jpg",
      __v: 0,
    },
    {
      _id: "663b4b96ae3d83941d7e60b7",
      title: "100$ Start Up",
      author: "Chris Guillebeau",
      category: "Self Help",
      cover: "100$ Start up.jpg",
      __v: 0,
    },
    {
      _id: "663b50eeae3d83941d7e60c0",
      title: "Our Mutual Friend",
      author: "Charles Dickens",
      category: "Novel",
      cover: "Our Mutual Friend.jpeg",
      __v: 0,
    },
    {
      _id: "663b557dae3d83941d7e60f2",
      title: "الكتابة عمل انقلابي",
      author: "نزار قباني",
      category: "شعر",
      cover: "الكتابة عمل انقلابي.jpg",
      __v: 0,
    },
    {
      _id: "663b55a2ae3d83941d7e60f5",
      title: "روت لي الأيام",
      author: "املي نصرالله",
      category: "قصص قصيرة",
      cover: "rawatlialayam.jpeg",
      __v: 0,
    },
    {
      _id: "663b55c4ae3d83941d7e60f8",
      title: "أوراق منسية",
      author: "املي نصرالله",
      category: "قصص قصيرة",
      cover: "awraqmanseya.jpg",
      __v: 0,
    },
    {
      _id: "663b52dcae3d83941d7e60c3",
      title: "نبضات من قلب الحياة",
      author: "كريم تقي الدين",
      category: "قصص قصيرة",
      cover: "cover.png",
      __v: 0,
    },
    {
      _id: "663b5381ae3d83941d7e60cb",
      title: "غريب و كلمة",
      author: "كريم تقي الدين",
      category: "قصص قصيرة",
      cover: "cover.png",
      __v: 0,
    },
    {
      _id: "663b53aaae3d83941d7e60ce",
      title: "احياء جيش",
      author: "رياض تقي الدين",
      category: "دراسة",
      cover: "cover.png",
      __v: 0,
    },
    {
      _id: "663b53d4ae3d83941d7e60d1",
      title: "وجهة نظر مشروع عقيدة عسكرية",
      author: "رياض تقي الدين",
      category: "دراسة",
      cover: "cover.png",
      __v: 0,
    },
    {
      _id: "663b540dae3d83941d7e60d4",
      title: "انا و الحياة",
      author: "رياض تقي الدين",
      category: "دراسة",
      cover: "cover.png",
      __v: 0,
    },
    {
      _id: "663b5422ae3d83941d7e60d7",
      title: "Atomic Habits",
      author: "James Clear",
      category: "Self Help",
      cover: "Atomic Habits.jpg",
      __v: 0,
    },
    {
      _id: "663b544fae3d83941d7e60da",
      title: "The Tipping Point",
      author: "Malcom Gladwell",
      category: "Self Help",
      cover: "The Tipping Point 1.webp",
      __v: 0,
    },
    {
      _id: "663b5471ae3d83941d7e60dd",
      title: "Death In Venice",
      author: "Thomas Mann",
      category: "Short Story",
      cover: "Death in Venice.jpg",
      __v: 0,
    },
    {
      _id: "663b5498ae3d83941d7e60e0",
      title: "The Death Cure",
      author: "James Dashner",
      category: "Novel",
      cover: "the death cure.jpg",
      __v: 0,
    },
    {
      _id: "663b54bfae3d83941d7e60e3",
      title: "Rich Dad Poor Dad",
      author: "Robert Kiyosaki",
      category: "Self Help",
      cover: "Rich Dad Poor Dad.jpg",
      __v: 0,
    },
    {
      _id: "663b54e3ae3d83941d7e60e6",
      title: "The Strange Case Of Dr Jekyll & Mr Hyde",
      author: "Robert Stevenson",
      category: "Novel",
      cover: "The Strange case of Dr jekyll & Mr Hyde.jpg",
      __v: 0,
    },
    {
      _id: "663b5505ae3d83941d7e60e9",
      title: "Looking For Alska",
      author: "John Green",
      category: "Novel",
      cover: "Looking for Alska.jpg",
      __v: 0,
    },
    {
      _id: "663b5525ae3d83941d7e60ec",
      title: "Light On Yoga",
      author: "BKS Iyengar",
      category: "Self Help",
      cover: "Light on Yoga.jpg",
      __v: 0,
    },
    {
      _id: "663b555aae3d83941d7e60ef",
      title: "The Oz Principle",
      author: "Roger Connors, Tom Smith, Craig Hickman",
      category: "Self Help",
      cover: "The OZ principle.jpg",
      __v: 0,
    },
    {
      _id: "663cbc9c5ce48fd44e94169d",
      title: "Blink",
      author: "Malcom Gladwell",
      category: "Self Help",
      cover: "Blink.jpg",
      __v: 0,
    },
    {
      _id: "663c8186aae4c9461068c73b",
      title: "Before Your Memory Fades",
      author: "Toshikazu Kawaguchi",
      category: "Novel",
      cover: "beforeyourmememoryfades.jpg",
      __v: 0,
    },
    {
      _id: "663c8421aae4c9461068c752",
      title: "صخرة طانيوس",
      author: "امين معلوف",
      category: "رواية",
      cover: "sakhrattanious.png",
      __v: 0,
    },
    {
      _id: "663b5636ae3d83941d7e60fb",
      title: "الديزيل",
      author: "ثاني السويدي",
      category: "رواية",
      cover: "diesel.jpg",
      __v: 0,
    },
    {
      _id: "663b565eae3d83941d7e60fe",
      title: "القاهرة الجديدة",
      author: "نجيب محفوظ",
      category: "رواية",
      cover: "alkahira.jpg",
      __v: 0,
    },
    {
      _id: "663b5684ae3d83941d7e6101",
      title: "ثلاثية نيويورك",
      author: "بول اوستر",
      category: "رواية",
      cover: "ثلاثية نيويورك.jpeg",
      __v: 0,
    },
    {
      _id: "663b56bcae3d83941d7e6107",
      title: "علاقات حميمية",
      author: "جوليات راثبون",
      category: "رواية",
      cover: "alaqathamima.jpg",
      __v: 0,
    },
    {
      _id: "663b56a1ae3d83941d7e6104",
      title: "سباقات المسافات الطويلة",
      author: "عبد الرحمن منيف",
      category: "رواية",
      cover: "سباقات المسافات الطويلة.jpeg",
      __v: 0,
    },
    {
      _id: "663b5b06ae3d83941d7e6150",
      title: "محكمة بدون قانون",
      author: "خلود يحيى",
      category: "رواية",
      cover: "cover.png",
      __v: 0,
    },
    {
      _id: "663b5aedae3d83941d7e614d",
      title: "العائد",
      author: "خليل تقي الدين",
      category: "رواية",
      cover: "العائد.jpeg",
      __v: 0,
    },
    {
      _id: "663b5ac9ae3d83941d7e614a",
      title: "ربع قرن من النضال",
      author: "كمال جنبلاط",
      category: "سياسة",
      cover: "ربع قرن من النضال.jpeg",
      __v: 0,
    },
    {
      _id: "663b59ddae3d83941d7e6141",
      title: "التذكار",
      author: "سيد جاد",
      category: "قصص قصيرة",
      cover: "cover.png",
      __v: 0,
    },
    {
      _id: "663b5a13ae3d83941d7e6144",
      title: "المنقذ من الضلال",
      author: "حجة اسلام الغزالي",
      category: "فلسفة",
      cover: "cover.png",
      __v: 0,
    },
    {
      _id: "663b5a62ae3d83941d7e6147",
      title: "اديسون",
      author: "سلسلة الناجون",
      category: "سيرة ذاتية",
      cover: "cover.png",
      __v: 0,
    },
    {
      _id: "663b5963ae3d83941d7e6138",
      title: "فيما يتعدى الحرف",
      author: "كمال جنبلاط",
      category: "تاريخ",
      cover: "فيما يتعدى الحرف.jpeg",
      __v: 0,
    },
    {
      _id: "663b598bae3d83941d7e613b",
      title: "صرخة ضمير",
      author: "الحان فرحات",
      category: "رواية",
      cover: "sarkhatdamir.jpg",
      __v: 0,
    },
    {
      _id: "663b59a6ae3d83941d7e613e",
      title: "نظام الصراع في الشرق الأوسط",
      author: "فولكر برتس",
      category: "تاريخ",
      cover: "نظام الصراع في الشرق الأوسط.jpeg",
      __v: 0,
    },
    {
      _id: "663b5892ae3d83941d7e612c",
      title: "موسم الهجرة الى الشمال",
      author: "الطيب صالح",
      category: "رواية",
      cover: "mawsamalhojra.jpg",
      __v: 0,
    },
    {
      _id: "663b58b0ae3d83941d7e612f",
      title: "الرغيف",
      author: "توفيق يوسف عواد",
      category: "رواية",
      cover: "alragif.jpg",
      __v: 0,
    },
    {
      _id: "663b58eaae3d83941d7e6132",
      title: "الفرح",
      author: "ديك الجن",
      category: "قصص قصيرة",
      cover: "الفرح.jpeg",
      __v: 0,
    },
    {
      _id: "663b591bae3d83941d7e6135",
      title: "جذور الحروب الاهلية",
      author: "فريدريك معتوق",
      category: "تاريخ",
      cover: "جذور الحروب الاهلية.jpeg",
      __v: 0,
    },
    {
      _id: "663b57f6ae3d83941d7e6120",
      title: "ثم لم يبقى احد",
      author: "اجاثا كريستي",
      category: "رواية",
      cover: "lamyabqaahad.jpg",
      __v: 0,
    },
    {
      _id: "663b57d8ae3d83941d7e611d",
      title: "مذكرات ادريان",
      author: "مرغريت يورسنار",
      category: "رواية",
      cover: "مذكرات ادريان.jpeg",
      __v: 0,
    },
    {
      _id: "663b57beae3d83941d7e611a",
      title: "ختيار الحواس",
      author: "علي سعيد",
      category: "رواية",
      cover: "اختيار الحواس.jpeg",
      __v: 0,
    },
    {
      _id: "663b56eaae3d83941d7e610a",
      title: "حكاية مجانين",
      author: "عبد السلام العجيلي",
      category: "رواية",
      cover: "cover.png",
      __v: 0,
    },
    {
      _id: "663b5702ae3d83941d7e610d",
      title: "لغز الموت",
      author: "مصطفى محمود",
      category: "رواية",
      cover: "legezalmawt.jpg",
      __v: 0,
    },
    {
      _id: "663b572bae3d83941d7e6110",
      title: "البؤساء",
      author: "فيكتور هيجو",
      category: "رواية",
      cover: "alboasaa.jpg",
      __v: 0,
    },
    {
      _id: "663b5746ae3d83941d7e6113",
      title: "مدن الملح-الاخدود",
      author: "عبد الرحمن منيف",
      category: "رواية",
      cover: "مدن الملح-الاخدود.jpeg",
      __v: 0,
    },
    {
      _id: "663b576fae3d83941d7e6116",
      title: "مدن الملح-التيه",
      author: "عبد الرحمن منيف",
      category: "رواية",
      cover: "مدن الملح-التيه.jpeg",
      __v: 0,
    },
    {
      _id: "663b5819ae3d83941d7e6123",
      title: "لا تصدق الكذبة",
      author: "كريشنا مورتي",
      category: "Self Help",
      cover: "لا تصدق الكذبة.jpeg",
      __v: 0,
    },
    {
      _id: "663b583fae3d83941d7e6126",
      title: "عند قدمي غاندي",
      author: "راجندرا برازاد",
      category: "سيرة ذاتية",
      cover: "aindqadamyigandi.jpeg",
      __v: 0,
    },
    {
      _id: "663b5878ae3d83941d7e6129",
      title: "قصة تجاربي مع الحقيقة",
      author: "غاندي",
      category: "سيرة ذاتية",
      cover: "tajarob.jpg",
      __v: 0,
    },
  ];

  useEffect(() => {
    setBooks(bookData);
    setNotAvailable(bookData.length === 0);
    // fetch("/shop/books")
    //   .then((response) => response.json())
    //   .then((data) => {
    //     setBooks(data);
    //     setNotAvailable(data.length === 0);
    //   })
    //   .catch((error) => console.error("Error fetching books:", error));
  }, []);

  // const handleSubmit = (e) => {
  //   e.preventDefault();
  //   const formattedKey = key
  //     .toLowerCase()
  //     .replace(/(^|\s)\S/g, (match) => match.toUpperCase());
  //   const fetchBooks = async () => {
  //     try {
  //       let queryParam = `query=${formattedKey}`;
  //       if (category) {
  //         queryParam += `&category=${category}`;
  //       }
  //       const response = await fetch(`/shop/books/search?${queryParam}`);
  //       const data = await response.json();
  //       setBooks(data);
  //       setNotAvailable(data.length === 0);
  //       setCurrentPage(1);
  //     } catch (error) {
  //       console.error("Error fetching books:", error);
  //     }
  //   };

  //   fetchBooks();
  // };

  const handleSubmit = (e) => {
    e.preventDefault();
    const formattedKey = key.trim().toLowerCase();
  
    const filteredBooks = bookData.filter((book) =>
      book.title.toLowerCase().includes(formattedKey)
    );
  
    if (category) {
      const filteredByCategory = filteredBooks.filter(
        (book) => book.category === category
      );
      setBooks(filteredByCategory);
      setNotAvailable(filteredByCategory.length === 0);
    } else {
      setBooks(filteredBooks);
      setNotAvailable(filteredBooks.length === 0);
    }
    setCurrentPage(1);
  };
  // const handleClear = () => {
  //   fetch("/shop/books")
  //     .then((response) => response.json())
  //     .then((data) => {
  //       setBooks(data);
  //       setKey("");
  //       setCategory("");
  //       setNotAvailable(data.length === 0);
  //       setCurrentPage(1);
  //     })
  //     .catch((error) => console.error("Error fetching books:", error));
  // };

  const handleClear = () => {
    setBooks(bookData);
    setKey("");
    setCategory("");
    setNotAvailable(bookData.length === 0);
    setCurrentPage(1);
  };

  const handleBtnClick = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };
  const indexOfLastBook = currentPage * booksPerPage;
  const indexOfFirstBook = indexOfLastBook - booksPerPage;

  if (!indexOfLastBook === 0) {
    setNotAvailable(true);
  }
  const currentBooks = Array.isArray(books)
    ? books.slice(indexOfFirstBook, indexOfLastBook)
    : [];

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  return (
    <div className="shop_wrapper">
      <Header />
      <div className="shop">
        <div className="shop_header">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <h2 className="shop_header_title">Books Collection</h2>
              </div>
            </div>
          </div>
        </div>
        <div className="search_bar_container">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="searchBar_wrapper">
                  <form
                    className="search_form"
                    action=""
                    method="GET"
                    autoComplete="off"
                  >
                    <div className="row">
                      <div className="col-lg-12">
                        <div className="row searchBar">
                          <div className="col-lg-2">
                            <div className="select_input mb-2">
                              <select
                                name="category"
                                id="category"
                                className="form-control"
                                value={category}
                                onChange={(e) => setCategory(e.target.value)}
                              >
                                <option value="">Category</option>
                                <option value="Novel">Novels</option>
                                {/* <option value="Fiction">Fiction</option> */}
                                <option value="Non-Fiction">Non-Fiction</option>
                                <option value="Self Help">Self Help</option>
                                <option value="Short Story">
                                  Short Stories
                                </option>
                                {/* <option value="Biography">Biographies</option> */}
                                <option value="رواية">روايات</option>
                                <option value="فلسفة">فلسفة</option>
                                {/* <option value="دين">دين</option> */}
                                <option value="تاريخ">تاريخ</option>
                                <option value="قصص قصيرة">قصص قصيرة</option>
                                <option value="دراسة">دراسة</option>
                                <option value="شعر">شعر</option>
                                <option value="سياسة">سياسة</option>
                                <option value="سيرة ذاتية">سيرة ذاتية</option>
                              </select>
                            </div>
                          </div>
                          <div className="col-lg-7 mb-2">
                            <div className="search_input">
                              <input
                                type="search"
                                name="search"
                                id="search"
                                className="form-control"
                                placeholder="What Book Are You Looking For?"
                                value={key}
                                onChange={(e) => setKey(e.target.value)}
                              />
                            </div>
                          </div>
                          <div className="col-lg-3 mb-2">
                            <div className="submitBtn_div">
                              <button
                                type="submit"
                                onClick={handleSubmit}
                                className="ctaBtn submitBtn"
                              >
                                Search
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </form>
                  <div className="clear_btn_div">
                    <button className="clearBtn" onClick={handleClear}>
                      clear
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {!notAvailable && books.length > 0 ? (
          <div className="books_collection">
            <div className="container">
              <div className="row">
                {currentBooks.map((book) => (
                  <div key={book._id} className="col-sm-6 col-md-6 col-lg-3">
                    <Book
                      img={book.cover}
                      title={book.title}
                      category={book.category}
                      author={book.author}
                    />
                  </div>
                ))}
              </div>
            </div>

            <nav aria-label="Page navigation">
              <ul className="pagination mt-5 d-flex justify-content-center">
                <li
                  className={`page-item ${currentPage === 1 ? "disabled" : ""}`}
                >
                  <button
                    className="page-link pagBtn"
                    onClick={() => {
                      paginate(currentPage - 1);
                      handleBtnClick();
                    }}
                  >
                    Previous
                  </button>
                </li>
                {Array.from(
                  { length: Math.ceil(books.length / booksPerPage) },
                  (_, index) => (
                    <li
                      key={index}
                      className={`page-item pagItem ${
                        currentPage === index + 1 ? "active" : ""
                      }`}
                    >
                      <button
                        className="page-link"
                        onClick={() => {
                          paginate(index + 1);
                          handleBtnClick();
                        }}
                      >
                        {index + 1}
                      </button>
                    </li>
                  )
                )}
                <li
                  className={`page-item ${
                    currentPage === Math.ceil(books.length / booksPerPage)
                      ? "disabled"
                      : ""
                  }`}
                >
                  <button
                    className="page-link pagBtn"
                    onClick={() => {
                      paginate(currentPage + 1);
                      handleBtnClick();
                    }}
                  >
                    Next
                  </button>
                </li>
              </ul>
            </nav>
          </div>
        ) : (
          <p className="not_available_message">Book not available</p>
        )}
      </div>
    </div>
  );
}

export default Shop;
