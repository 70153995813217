import "bootstrap/dist/css/bootstrap.css";
import "bootstrap/dist/js/bootstrap.bundle.min";
import "bootstrap/dist/css/bootstrap.min.css.map";
import "../assets/styles.css";
import React from "react";
import { Routes, Route } from "react-router-dom";
import Home from "../pages/Home";
import ContactUs from "../pages/ContactUs";
import Shop from "../pages/Shop";
import Admin from "../pages/Admin";

function App() {
  // const [user, setUser] = useState(true);

  // useEffect(() => {
  //   const token = localStorage.getItem("token");
  //   setUser(token ? true : false);
  // }, []);

  return (
    <div>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/contactus" element={<ContactUs />} />
        <Route path="/shop/books" element={<Shop />} />
        {/* <Route path="/admin/login" element={<Login />} /> */}
        {/* <Route
          path="/admin/panel"
          element={user ? <Admin /> : <Login to="/admin/login" />}
        /> */}
        {/* <Route path="/admin/panel" element={<Admin />} /> */}
      </Routes>
    </div>
  );
}

export default App;
