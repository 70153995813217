
import React, { useState} from "react";
import { Link } from "react-router-dom";

function Header() {
  const [showNav, setShowNav] = useState(false);

  const toggleNavbar = () => {
    setShowNav(!showNav);
  };

  const hideMenu = () => {
    setShowNav(false);
  };

  return (
    <div>
      <div className="header">
        <div className="container">
          <div className="header_content">
            <Link to="/">
              <div className="logo">
                <img src={require("..//assets/images/logo.png")} alt="logo" />
              </div>
            </Link>
            <div className={`nav_links ${showNav ? "show" : ""}`}>
              <ul className="nav_list">
                <Link to="/" className="nav_link" onClick={hideMenu}>
                  <li>Home</li>
                </Link>

                <Link to="/shop/books" className="nav_link" onClick={hideMenu}>
                  {" "}
                  <li>Books</li>
                </Link>
              </ul>
            </div>
            <div className="nav_cta">
              <Link to="/contactus">
                <button className="ctaBtn headerBtn">Contact Us</button>
              </Link>
              <div className="mobile_menu" onClick={toggleNavbar}>
                <img
                  src={
                    showNav
                      ? require("..//assets/images/close-icon.png")
                      : require("..//assets/images/ham_menu.png")
                  }
                  alt="Mobile Menu Icon"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Header;
